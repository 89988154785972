
import { defineComponent, onMounted, ref, watchEffect } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { forkJoin } from "rxjs";
import * as _ from "lodash";
import Papa from "papaparse";
import { createDeviceByCsV } from "@/store/api/devices"; 
import { ModelSelect } from 'vue-search-select'
import "vue-search-select/dist/VueSearchSelect.css";
import { 
  credentialSheetCsv,
  createDeviceByRepo } from "@/store/api/devices";

interface IHeader {
  name: string;
  key: string;
}
export default defineComponent({
  name: "add-repo",
  components: {
    //KTDatatable,
    ModelSelect
  },
  setup() {
    const fileList = ref([]);
    const options = ref<{ value: string; text: string }[]>([]); 
    const item = ref(null); 
    const file = ref("");
    const content = ref([]);
    const parsed = ref<boolean>(false);
    const uploadBtn = ref<boolean>(true);
    const credentialsBtn = ref<boolean>(false);
    const fileStatus = ref<boolean>(false);
    const loading = ref<string>("off");
    const header = ref<IHeader[]>([]);
    const data = ref<any[]>([]);
    const variant = ref<string>("");
    const selectedFile = ref<File | null>(null);
    const lns = ref<string>("");
    const protocol = ref<string>("");
    const network_connectivity = ref<string>("");
    const shipment_date = ref<string>("");
    const filename = ref<string>("");
    const searchValue = ref<any>("");
    const respCSV = ref<any>({
      added: [],
      errors: [],
      skipped: [],
      success: false,
    });
    let response: any = {};
    onMounted(async () => {
      setCurrentPageBreadcrumbs("Add Repo By CSV", [""]);
      // await loadOptions();
    });
    watchEffect(async () => {
      // await loadOptions();
      const result = await credentialSheetCsv();
      fileList.value = result.data.data;
      options.value = fileList.value.map((filename, index) => ({
        value: filename,
        text: filename
      }));
      console.log("options", options)
    });

    const loadOptions = async () => {
      const result = await credentialSheetCsv();
      fileList.value = result.data.data;
    };

    const handleFileUpload = (event) => {
      let file_type = event.target.files[0].type;
      console.log("Type: ", file_type);
      if (file_type == "text/csv") {
        file.value = event.target.files[0];
        fileStatus.value = false;      
      }      
      else {
        file.value = "";
        fileStatus.value = true;
      }
      parseFile();
    };
    const isButtonDisabled = () => {
      return !file.value || !variant.value;
    };

    const credentialButtonDisabled = () => {
      return !variant.value || !filename.value;
    };
    
    const searchData = (val) => {
      if (val != "") {
        data.value = data.value.filter(
          (v) =>
            v["Device Address"] == val ||
            v["Unique Random Number"] == val ||
            v["Device EUI"] == val ||
            v["Application EUI"] == val ||
            v["Device Serial Number"] == val ||
            v["DEVICE SERIAL ID"] == val ||
            v["DEVICE UID"] == val ||
            v["DEVICE EUI"] == val ||
            v["APPLICATION EUI"] == val ||
            v["DEVICE IMEI"] == val ||
            v["ICCID"] == val
        );
      } else {
        data.value = content.value;
      }

      // return value;
    };
    
    const parseFile = () => {
      Papa.parse(file.value, {
        header: true,
        skipEmptyLines: true,
        complete: function (results) {
          content.value = results.data;
          for (const key in content.value[0]) {
            let json = {
              key: key,
              name: key,
            };
            header.value.push(json);
          }
          data.value = content.value;
          parsed.value = true;
          loading.value = "off";
        }.bind(this),
      });
    };
    const showButton1Data = () => {
      uploadBtn.value = true
      credentialsBtn.value = false
    };
    const showButton2Data = () => {
      uploadBtn.value = false
      credentialsBtn.value = true
    };

    const submit = () => {
      loading.value = "on";
      let payload = {
        variant: variant.value,
        devices: content.value,
        lns: lns.value,
        protocol: protocol.value,
        network_connectivity: network_connectivity.value,
        shipment_date: shipment_date.value
      };
      let chunk = [...createChunks(payload.devices, 50)];
      let ajax_calls = ref<any[]>([]);
      for (var i = 0; i < chunk.length; i++) {
        ajax_calls.value.push(
          createDeviceByCsV({
            devices: chunk[i],
            variant: payload.variant,
            lns: payload.lns,
            protocol: payload.protocol,
            network_connectivity: payload.network_connectivity,
            shipment_date: payload.shipment_date
          })
        );
      }
      forkJoin(ajax_calls.value).subscribe(
        (data) => {
          for (var i = 0; i < chunk.length; i++) {
            respCSV.value = _.mergeWith(response, data[i], joinResults);
          }
          response = {};
          loading.value = "off";
        },
        (err) => {
          console.log(err);
        }
      );
    };

    const submitCredential = async () => {      
      let res = await createDeviceByRepo({
        file: filename.value,
        variant: variant.value,
        lns: lns.value,
        protocol: protocol.value,
        network_connectivity: network_connectivity.value,
        shipment_date: shipment_date.value
      });
      respCSV.value.success = res.success;
      respCSV.value.errors = res.errors;
      respCSV.value.added = res.added;
      respCSV.value.skipped = res.skipped;

    }

    const isObject = (val: boolean) => {
      return typeof val === "object";
    };
    return {
      header,
      searchValue,
      searchData,
      data,
      parsed,
      content,
      respCSV,
      variant,
      filename,
      handleFileUpload,
      submit,
      submitCredential,
      isObject,
      loading,
      options,
      selectedOption: null,
      uploadBtn,
      credentialsBtn,
      fileStatus,
      showButton1Data,
      showButton2Data,
      lns,
      protocol,
      network_connectivity,
      shipment_date,
      isButtonDisabled,
      credentialButtonDisabled
    };
  },
});

function* createChunks(arr, n) {
  for (let i = 0; i < arr.length; i += n) {
    yield arr.slice(i, i + n);
  }
}

function joinResults(objValue, srcValue) {
  if (_.isArray(objValue)) {
    return objValue.concat(srcValue);
  }
}
